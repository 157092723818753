@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');

$bgColor: #25292c;
$bgSecondary: #484B4F;
$bgDark: #34373B;
$textColor: #ffff;
$accentColor: #4FFD80;
$accentColorDimmed: #75ebbb;
$dcBlueColor: #5865F2;

$containerWidth: 1200px;

$bpPhone: 320px;
$bpTablet: 768px;
$bpDesktop: 1024px;

html {
    overflow-x: hidden;
}

body {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 1.5;
    margin: 2rem;
    color: $textColor;
    background-color: $bgColor;

    @media (max-width: $bpTablet) {
        margin: 0;
    }
}

::-webkit-scrollbar {
    width: 0px;
}
  

a {
    text-decoration: none;
    color: $accentColor;
    transition: 0.2s;

    &:hover {
        color: $accentColorDimmed;
    }
}

.nav-container {
    display: flex;
    justify-content: space-around;
    align-items: baseline;
    padding-bottom: 100px;
    margin: 0;
}

.navbar {
    overflow: hidden;
    display: flex;
    position: fixed;
    top: 0px;
    width: 100%;
    height: 100px;
    max-width: $containerWidth;
    padding: 0;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    margin: 0;
    background-color: $bgColor;
    border-bottom: 1px solid rgb(56, 56, 56);

    .burgerMenu {
        display: none;
        font-size: 2rem;
        margin: 30px;

        @media (max-width: $bpTablet) {
            display: block;
        }
    }

    .collapse {
        @media (max-width: $bpTablet) {
            display: none;
        }
    }

    li {
        list-style: none;
        float: left;

        @media (max-width: $bpTablet) {
            margin: 30px;
        }
    }

    li a:not(.img-link) {
        display: block;
        text-align: center;
        color: $textColor;
        padding: 14px 16px;
        border-radius: 5px;
        margin-right: 10px;

        &:hover {
            background-color: #111;
        }
    }

    .inviteButton {
        background-color: $accentColor;
        color: black !important;
        font-size: 1rem;
        font-weight: bold;
        margin-right: 0 !important;

        &:hover {
            background-color: $accentColorDimmed !important;
        }
    }

    .logo {
        height: 50px;
        width: 50px;
        padding: 0;
    }

    @media (max-width: $bpTablet) {
       margin-top: 0px;
    }
}

.container {
    max-width: $containerWidth;
    margin: auto;

    @media (max-width: $bpTablet) {
        margin: 2rem;
    }
}

.leaderboard {
    .entry {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        background-color: $bgDark;
        border-radius: 10px;
        padding: 0px 20px;

        background:linear-gradient(0.1turn, #161615, #18100e, #141313, #180d0a,#161615, #18100e, #141313, #180d0a);

        &.pos-0 {
            background: linear-gradient(0.1turn, #edc800, #e3b600, #f3cf00, #ffe800,#edc800, #e3b600, #c9ae1a, #a19510);
        }

        &.pos-1 {
            background:linear-gradient(0.1turn, #d7d7d7, #c3c3c3, #cccccc, #c6c6c6,#d7d7d7, #c3c3c3, #a09f9f, #9b9696);
        }

        &.pos-2 {
            background:linear-gradient(0.1turn, #d95641, #b14439, #b2453a, #d25645, #d95641, #b14439, #88382f, #a13c2e);
        }

        .name {
            font-size: 1.3rem;
            font-weight: bold;
            margin-right: 10px;
            max-width: 400px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            .botIcon {
                margin-left: 10px;
                padding: 5px;
                border-radius: 50%;
                vertical-align: middle;
                background-color: $dcBlueColor;
            }

            .medal {
                margin-right: 10px;
                font-size: 2rem;
                vertical-align: middle
            }
        }

        @media (max-width: $bpPhone) {
            .name {
                max-width: 200px;
            }
        }

        .score {
            font-size: 1.5rem;
            font-weight: bold;
            margin-right: 10px;
        }

        .stats {
            width: 60px;

            svg {
                height: 20px;
                width: 20px;
                margin-right: 10px;
                vertical-align: top;
            }
    
            .wins {
                color: gold;
                filter: drop-shadow(0px 0px 10px black);
            }    

            .loses {
                color: #e64848;
                filter: drop-shadow(0px 0px 10px black);
            }
        }
    }
}

.menu {
    a:not(.burgerMenu) {
        background-color: $bgDark;
        padding: 15px;
        border-radius: 5px;
        display: block;
        margin-bottom: 10px;
        text-align: center;
        font-weight: bold;

        &.inviteButton {
            background-color: $accentColor;
            color: black !important;
            font-size: 1rem;
            font-weight: bold;
            margin-right: 0 !important;

            &:hover {
                background-color: $accentColorDimmed !important;
            }
        }
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        .burgerMenu {
            font-size: 2rem;
            vertical-align: top;
        }
    }
}

.team {
    
    .members {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        flex-grow: initial;
    }

    .member {
        width: 45%;
        min-width: 300px;
        background-color: $bgDark;
        border-radius: 10px;
        position: relative;
        margin: 10px;

        .accentColor {
            background-color: #88382f;
            border-radius: 10px 10px 0px 0px;
            width: 100%;
            height: 60px;
        }

        .avatar img {
            position: absolute;
            width: 80px;
            border-radius: 50%;
            top: 20px;
            left: 20px;
            filter: drop-shadow(0px 0px 15px black);
        }

        .holder {
            margin: 10px;
            margin-top: 30px;
            padding: 20px;
        }

        .name {
            font-weight: bold;
            font-size: larger;

            .tag {
                color: rgb(146, 145, 145);
            }
        }

        hr {
            border: .2px solid #636262;
            border-radius: 1px;
        }

        .categoryTitle {
            font-weight: bold;
            margin-top: 20px;
            margin-bottom: 10px;
            text-transform: uppercase;
        }

        .roleCard {
            background-color: $bgSecondary;
            border-radius: 10px;
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            .dot {
                width: 15px;
                height: 15px;
                background-color: $accentColor;
                border-radius: 50%;
                margin: 0px 10px;
            }

            .name {
                font-size: 0.9rem;
                font-weight: 500;
            }
        }
        
    }
}

.landing {

    .container {
        position: relative;
    }

    .animation-container {
        height: 1000vh;

        canvas {
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-height: 100vh;
            max-width: 100vw;
            z-index: -1;
        }
    
        .block {
            position: absolute;
            border-radius: 10px;
        }

        .text-box {
            position: fixed;
            margin-top: 50px;
            width: 550px;
            height: 500px;

            @media (max-width: 1700px) {
                width: 500px;
                height: 400px;
            }

            @media (max-width: 1040px) {
                width: 400px;
                height: 300px;
            }
        }

        .animation {
            @media screen and (max-width: 900px) {
                display: none;
            }
        }
    }

    .overwiew {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: $containerWidth;

        .item {
            background-color: $bgDark;
            padding: 10px 20px;
            margin: 10px;
            width: 22%;
            height: 300px;
            min-width: 300px;
            border-radius: 10px;

            @media(max-width: $bpPhone) {
                width: 100%;
                min-width: 200px;
            }

            @media(max-height: 900px) {
                &.prio-3 {
                    display: none;
                }
            }

            @media(max-height: 900px) {
                &.prio-2 {
                    display: none;
                }
            }

            @media(max-width: 900px) {
                &.prio-2 {
                    display: none;
                }

                &.prio-3 {
                    display: none;
                }
            }

            .invite-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 100%;
                width: 100%;
                border-radius: 10px;
                margin-top: 30px;

                .invite {
                    all: unset;
                    background-color: $accentColor;
                    color: black !important;
                    font-size: 2rem;
                    text-align: center;
                    font-weight: bold;
                    width: 80%;
                    height: 60px;
                    border-radius: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &:hover {
                        background-color: $accentColorDimmed !important;
                    }
                }
            }
        }
    }
} 


.mobileLanding {

    img.previewIMG {
        position: absolute;
        left: 0;
        width: 80%;
        margin: 0;
        margin-top: 40px;
    }

    .titleSection {
        display: flex;
        flex-direction: column;
        justify-content: end;
    }

    .title {
        font-size: 3rem;
        font-weight: bold;
        text-align: end;
        margin: 0;
        margin-bottom: 20px;
    }

    .subtitle {
        font-size: 1.5rem;
        font-weight: bold;
        text-align: end;
        margin-top: 23rem;
        margin-bottom: 5px;
    }

    .invite {
        background-color: $accentColor;
        color: black;
        padding: 15px 40px;
        border-radius: 10px;
        margin-left: auto;
    }

    .infos {
        margin-top: 30px;
        padding-bottom: 30px;
        width: 100%;
        border-top: 1px solid gray;
        background: rgba(0, 0, 0, 0.233);

        .info {
            margin: 30px 15px;

            .title {
                font-size: 1.5rem;
                font-weight: bold;
                text-align: left;
                margin: 0
            }

            .seperator {
                width: 20%;
                margin-bottom: 20px;
                margin-right: 80%;
            }

            .invite-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 100%;
                width: 100%;
                border-radius: 10px;
                margin-top: 30px;

                .invite {
                    all: unset;
                    background-color: $accentColor;
                    color: black !important;
                    font-size: 1.5rem;
                    text-align: center;
                    font-weight: bold;
                    width: 50%;
                    height: 40px;
                    border-radius: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &:hover {
                        background-color: $accentColorDimmed !important;
                    }
                }
            }
        }
    }
}


.docs {
    code {
        background-color: $bgSecondary;
        padding: 5px;
        border-radius: 5px;
        margin: 2px;
    }

    .commands {
        line-height: 35px;
    }

    @media (max-width: $bpTablet) {
        img {
            object-fit: contain;
            max-width: 100%;
            height: auto;
        }
    }
}


footer {
    color: white;
    text-align: center;
    font-size: 0.8rem;
    font-weight: bold;
    margin-top: 50px;
}